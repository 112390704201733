@charset 'utf-8';
@use "../index" as *;

// ------------ BASS LAYOUT ------------

// ベースのコンテンツ幅
.l-wrapper {
  width: 95%;
  max-width: 1000px;
  margin-inline: auto;
  position: relative;

  @include mq() {
    width: calc(335 / 375 * 100%);
  }
}