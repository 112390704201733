@charset "utf-8";
@use "breakpoint" as *;

// ホバーができるデバイスのみに適用
@mixin hover {
	@media (hover: hover) {
		&:hover {
			@content;
		}
	}
}