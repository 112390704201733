@charset "utf-8";
@use "../index" as *;

// padding
.u-pt10 {
	margin-top: 10px;
}

.u-pt20 {
	margin-top: 20px;
}

.u-pt30 {
	margin-top: 30px;
}

.u-pt50 {
	margin-top: 50px;
}

.u-pt100 {
	margin-top: 100px;
}

@include mq() {
	.u-pt20 {
		margin-top: 15px;
	}

	.u-pt30 {
		margin-top: 20px;
	}

	.u-pt50 {
		margin-top: 30px;
	}

	.u-pt100 {
		margin-top: 50px;
	}
}

@include mq(sp) {
	.u-pt20 {
		margin-top: 15px;
	}

	.u-pt50 {
		margin-top: 25px;
	}
}


.u-pb10 {
	margin-bottom: 10px;
}

.u-pb20 {
	margin-bottom: 20px;
}

.u-pb30 {
	margin-bottom: 30px;
}

.u-pb50 {
	margin-bottom: 50px;
}

.u-pb100 {
	margin-bottom: 100px;
}

@include mq() {
	.u-pb20 {
		margin-bottom: 15px;
	}

	.u-pb30 {
		margin-bottom: 20px;
	}

	.u-pb50 {
		margin-bottom: 30px;
	}

	.u-pb100 {
		margin-bottom: 50px;
	}
}

@include mq(sp) {
	.u-pb20 {
		margin-bottom: 15px;
	}

	.u-pb50 {
		margin-bottom: 25px;
	}
}


.u-pr05 {
	margin-right: 5px;
}

.u-pr10 {
	margin-right: 10px;
}

.u-pr15 {
	margin-right: 15px;
}

.u-pr20 {
	margin-right: 20px;
}

.u-pr30 {
	margin-right: 30px;
}

.u-pl05 {
	margin-left: 5px;
}

.u-pl10 {
	margin-left: 10px;
}

.u-pl15 {
	margin-left: 15px;
}

.u-pl20 {
	margin-left: 20px;
}

.u-pl30 {
	margin-left: 30px;
}