@charset "utf-8";
@use "../index" as *;

%c-btn {
  height: 46px;
  padding: 0 14px 0 25px;
  background-color: $main-color;
  border-radius: 8px;

  font-size: 20px;
  font-weight: $medium;
  line-height: calc(20 / 29);

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 3px 3px 0 rgba($color: #000000, $alpha: .18);
  transition: .3s;

  @include mq() {
    height: 35px;
    padding: 0 11px 0 16px;
    font-size: 14px;
  }

  &::after {
    content: "";
    display: block;
    width: 6px;
    height: 12px;
    background-image: url(../img/arrow/arrow_right_w.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8px;

    @include mq() {
      margin-left: 24px;
    }
  }

  @include hover {
    box-shadow: none;
    transform: translate(3px, 3px);
  }
}

.c-btn {
  @extend %c-btn;
  @include link-color(#fff);

  &.--store {
    background-image: url(../img/front/icon_store_w.svg);
    background-size: 22px auto;
    background-position: left 14px center;
    padding-left: 40px;

    @include mq() {
      background-size: 16px auto;
    }

    &::after {
      transform: rotate(90deg);

      @include mq() {
        margin-left: 14px;
      }
    }
  }
}

.c-btnWrap {
  text-align: center;
}

.c-btnReturn {
  @extend %c-btn;
  @include link-color(#fff);

  &::after {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    width: 6px;
    height: 12px;
    background-image: url(../img/arrow/arrow_left_w.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8px;

    @include mq() {
      margin-right: 24px;
    }
  }

  @include hover {
    box-shadow: none;
    transform: translate(3px, 3px);
  }
}


// バリエーション
.c-btn--stampEntry {
  @extend %c-btn;
  padding: 0 12px 0 42px;
  @include link-color(#fff);
  font-size: 15px;
  letter-spacing: -0.1em;
  line-height: calc(16 / 15);
  background-image: url(../img/front/icon_stamp_w.svg);
  background-repeat: no-repeat;
  background-size: 22px auto;
  background-position: left 12px center;

  @include mq() {
    height: 60px;
    padding: 0px 12px 0 28px;
    background-size: 18px auto;
    background-position: left 5px center;

    &.--follow {
      border-radius: 8px 0 0 8px;
    }
  }
}

.c-btnWrap--stampEntry {
  position: fixed;
  z-index: 100;

  @include mq_up() {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba($color: #000000, $alpha: .1);
    bottom: 1.5vh;
    left: 20px;
  }

  @include mq() {
    bottom: 33px;
    right: 0;
  }
}