@charset "utf-8";
@use "../index" as *;

$fuwafuwa-func: cubic-bezier(.04, 1.57, .27, .97);

/* --- Slideup Effects without Buttons and links START --- */
/* --- It needs class="c-slideup" and "init"--- */
.c-slideup {
	opacity: 1;

	// ふわーっと出現
	// transition: transform 3s $fuwafuwa-func, opacity 0.5s ease-in-out;

	// シンプルに出現
	// transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
	// transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
	transition: opacity 0.7s ease-in-out, transform 1.5s cubic-bezier(0.35, 0.34, 0.22, 0.99);

	transform: translate(0, 0);
	will-change: transform, opacity;

	&.init {
		opacity: 0;
		// ふわーっと出現
		transform: translate(0, 100px);

		@include mq() {
			transform: translate(0, 80px);
		}

		// シンプルに出現
		// transform: translate(0, 50px);
	}
}

/* --- Slideup Effects END --- */

@include mq_up(sp) {
	.delay_100 {
		transition-delay: 100ms;
	}

	.delay_200 {
		transition-delay: 200ms;
	}

	.delay_300 {
		transition-delay: 300ms;
	}

	.delay_400 {
		transition-delay: 400ms;
	}

	.delay_400_0 {
		transition-delay: 400ms;

		@include mq(sp) {
			transition-delay: 0;
		}
	}
}