@charset "utf-8";
@use "../index" as *;

// 共通セクションタイトル
%c-ttl {
  @extend %fontSize_36;
  font-weight: $bold;
  line-height: calc(52 / 36);
  text-align: center;
  margin-bottom: 24px;
}

.c-ttl {
  @extend %c-ttl;

  span {
    font-size: 22px;

    @include mq() {
      font-size: 18px;
    }
  }
}

.c-ttl--icon {
  @extend %c-ttl;
  margin-bottom: 35px;
  background-repeat: no-repeat;
  background-position: top center;

  &.--howto {
    padding-top: 56px;
    background-image: url(../img/front/icon_ttl_howto.svg);
    background-size: 54px auto;
  }

  &.--present {
    padding-top: 48px;
    background-image: url(../img/front/icon_ttl_present.svg);
    background-size: 42px auto;
  }

  &.--participation {
    padding-top: 55px;
    background-image: url(../img/front/icon_ttl_participation.svg);
    background-size: 50px auto;
  }

  &.--diet {
    padding-top: 63px;
    background-image: url(../img/front/icon_ttl_diet.svg);
    background-size: 77px auto;
  }

  &.--mb10 {
    margin-bottom: 10px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  &.--mb45 {
    margin-bottom: 45px;

    @include mq() {
      margin-bottom: 30px;
    }
  }
}