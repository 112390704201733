@charset "utf-8";
@use "../index" as *;

// フッター

.l-footer {
  background-color: #F9F6ED;

  .l-footer__inner {
    padding: 40px 20px;
    max-width: 795px;
    margin: 0 auto;
  }

  .l-footer__host {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.4;

    @include mq(sssp) {
      font-size: 12px;
    }

    a {
      color: $main-color;
    }
  }
  .l-footer__host__head {
    margin-bottom: 1em;
  }
  .l-footer__host__tel {
    @include mq_up(pc) {
      pointer-events: none;
    }
  }
  .l-footer__host__fax {
    pointer-events: none;
  }

  .l-footer__sponsor {
    margin: 20px auto 0;
  }

  // .l-footer__logo {
  //   img {
  //     margin-inline: auto;
  //     height: auto;

  //     @include mq() {
  //       max-width: 300px;
  //     }
  //   }
  // }

  .l-footer__copyright {
    text-align: center;
    font-size: 12px;
  }
}