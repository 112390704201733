@charset 'utf-8';
@use "../index" as *;

.p-participation {
	max-width: 1366px;
	margin-inline: auto;
	padding-top: 167px;
	padding-bottom: 0;
	position: relative;

	@include mq() {
		padding-top: 137px;
	}

	&::before,
	&::after {
		content: "";
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
	}

	&::before {
		width: 414px;
		height: 257px;
		background-image: url(../img/front/sparkle_01.webp);
		top: 80px;
		left: -211px;

		@include mq() {
			width: 214px;
			height: 133px;
			top: 20px;
			left: -101px;
		}
	}

	&::after {
		width: 153px;
		height: 334px;
		background-image: url(../img/front/sparkle_02.webp);
		top: 108px;
		right: -25px;

		@include mq() {
			width: 98px;
			height: 202px;
			top: 178px;
		}
	}
}

.p-participation__wrapper {
	max-width: 1000px;
}

.p-participation__areaTtl {
	@extend %fontSize_26;
	font-weight: $bold;
	letter-spacing: -0.07em;
	line-height: calc(37 / 26);
	margin-bottom: 46px;

	@include mq() {
		margin-bottom: 20px;
	}

	strong {
		font-size: 35px;

		@include mq() {
			font-size: 28px;
		}
	}
}

.p-participation__list {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 319px));
	gap: 42px 21px;
	position: relative;
	z-index: 1;

	@include mq() {
		grid-template-columns: repeat(2, minmax(0, 50%));
	}

	@include mq(sp) {
		grid-template-columns: repeat(1, minmax(0, 100%));
	}

	li {
		@extend %fontSize_24;
		font-weight: $bold;
		line-height: calc(35 / 24);

		div {
			aspect-ratio: 319 / 240;
			margin-bottom: 10px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 20px;
			}
		}

		span {
			display: block;
			font-size: 15px;
		}
	}
}