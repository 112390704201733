@charset "utf-8";
@use "../index" as *;

// margin
.u-mt10 {
	margin-top: 10px;
}

.u-mt20 {
	margin-top: 20px;
}

.u-mt30 {
	margin-top: 30px;
}

.u-mt40 {
	margin-top: 40px;
}

.u-mt50 {
	margin-top: 50px;
}

.u-mt100 {
	margin-top: 100px;
}

.u-mtAuto {
	margin-top: auto;
}

@include mq() {
	.u-mt20 {
		margin-top: 15px;
	}

	.u-mt30 {
		margin-top: 20px;
	}

	.u-mt40 {
		margin-top: 25px;
	}

	.u-mt50 {
		margin-top: 30px;
	}

	.u-mt100 {
		margin-top: 50px;
	}
}

@include mq(sp) {
	.u-mt20 {
		margin-top: 15px;
	}

	.u-mt50 {
		margin-top: 25px;
	}
}

.u-mb10 {
	margin-bottom: 10px;
}

.u-mb20 {
	margin-bottom: 20px;
}

.u-mb30 {
	margin-bottom: 30px;
}

.u-mb40 {
	margin-bottom: 40px;
}

.u-mb50 {
	margin-bottom: 50px;
}

.u-mb100 {
	margin-bottom: 100px;
}

@include mq() {
	.u-mb20 {
		margin-bottom: 15px;
	}

	.u-mb30 {
		margin-bottom: 20px;
	}

	.u-mb40 {
		margin-bottom: 25px;
	}

	.u-mb50 {
		margin-bottom: 30px;
	}

	.u-mb100 {
		margin-bottom: 50px;
	}
}

@include mq(sp) {
	.u-mb20 {
		margin-bottom: 15px;
	}

	.u-mb50 {
		margin-bottom: 25px;
	}
}


.u-mr05 {
	margin-right: 5px;
}

.u-mr10 {
	margin-right: 10px;
}

.u-mr15 {
	margin-right: 15px;
}

.u-mr20 {
	margin-right: 20px;
}

.u-mr30 {
	margin-right: 30px;
}

.u-ml05 {
	margin-left: 5px;
}

.u-ml10 {
	margin-left: 10px;
}

.u-ml15 {
	margin-left: 15px;
}

.u-ml20 {
	margin-left: 20px;
}

.u-ml30 {
	margin-left: 30px;
}