@charset "utf-8";
@use "../index" as *;

// ------------ 1st view ------------

.p-fv {
  overflow: hidden;
  position: relative;
}

.p-fv__inner {
  width: 100%;
  max-width: 1366px;
  height: 754px;
  margin-inline: auto;
  position: relative;

  @include mq(pc) {
    height: 554px;
  }

  @include mq() {
    height: calc(505 / 375 * 100vw);
  }

  &::before {
    content: "";
    display: block;
    width: 1551px;
    height: 754px;
    background-image: url(../img/front/fv_pc.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    position: absolute;
    top: 0;
    left: -107px;

    @include mq(pc) {
      height: 554px;
      left: auto;
      right: -100px;
    }

    @include mq() {
      width: 100%;
      height: 100%;
      background-image: url(../img/front/fv_sp.webp);
      left: 0;
    }
  }
}

.p-fv__tag {
  background-color: $main-color;
  color: #fff;
  width: 420px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 65px;
  left: unquote('max(calc((100% - 1366px) / 2 + 65px), 65px)');
  border-radius: 0 0 5px 5px;
  z-index: 1;

  &__image {
    width: 90%;
    height: auto;
  }

  @include mq() {
    position: static;
    width: 100%;
    height: 50px;
    border-radius: 0;
    &__image {
      max-width: 500px;
    }
  }
  @include mq(sp) {
    height: 40px;
    &__image {
      max-width: 338px;
    }
  }

}

.p-fv__imgCampaign {
  height: auto;
  position: absolute;
  bottom: -6px;
  right: 9px;
  animation: furifuri 5s ease infinite;

  @include mq() {
    width: calc(181 / 375 * 100vw);
    bottom: 23px;
    right: 12px;
    z-index: 1;
  }
}


// ------------ FV下 概要 ------------

.p-outline {
  padding-top: 75px;
  max-width: 1336px;
  margin-inline: auto;
  position: relative;

  @include mq() {
    padding-top: 5px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }

  &::before {
    width: 153px;
    height: 334px;
    background-image: url(../img/front/sparkle_02.webp);
    top: -21px;
    right: -40px;

    @include mq() {
      width: calc(98 / 375 * 100%);
      height: calc(202 / 375 * 100vw);
      top: -118px;
      right: -12px;
    }
  }

  &::after {
    width: 415px;
    height: 334px;
    background-image: url(../img/front/sparkle_03.webp);
    bottom: 183px;
    left: -202px;

    @include mq() {
      width: calc(82 / 375 * 100%);
      height: calc(202 / 375 * 100vw);
      background-image: url(../img/front/sparkle_04.webp);
      bottom: -141px;
      left: 0;
    }
  }
}

.p-outline__lead {
  @extend %fontSize_22;
  font-weight: $bold;
  line-height: calc(48 / 22);
  text-align: center;

  @include mq(sp) {
    font-size: 16px;
  }
  @include mq(sssp) {
    font-size: 14px;
  }
}

.p-outline__stampGuide {
  margin-top: 53px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 75px;

  @include mq() {
    margin-top: 20px;
    flex-direction: column;
    gap: 69px;
  }
}

.p-outline__stampGuide__content {
  background-color: $box;
  border-radius: 20px;
  display: flex;

  @include mq() {
    height: 172px;
    justify-content: center;
    align-items: center;
  }

  +.p-outline__stampGuide__content {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 31px;
      height: 37px;
      background-image: url(../img/arrow/arrow_right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: -52px;
      transform: translateY(-50%);

      @include mq() {
        top: -52px;
        left: calc(50% - 31px);
        transform: rotate(90deg) translate(0, -50%);
      }
    }
  }

  @include mq_up() {
    &.--largeBox {
      width: 63%;
      max-width: 623px;
      justify-content: space-between;
      align-items: center;
      padding: 54px 91px 54px 63px;
    }

    &.--smallBox {
      width: 31%;
      max-width: 302px;
      justify-content: center;
      align-items: center;
    }
  }

  .or {
    display: block;
    width: 103px;
    height: 103px;
    background-color: #F9F6ED;
    border-radius: 103px;
    @extend %fontSize_18;
    font-weight: $bold;
    line-height: 103px;
    text-align: center;

    @include mq() {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    @include mq(sp) {
      font-size: 12px;
    }
  }
}

.p-outline__stampGuide__item {
  p {
    @extend %fontSize_18;
    font-weight: $bold;
    line-height: calc(26 / 18);
    text-align: center;
    background-repeat: no-repeat;

    @include mq(sp) {
      font-size: 14px;
    }

    &.meal {
      padding-top: 80px;
      background-image: url(../img/front/icon_meal.svg);
      background-size: 100px auto;
      background-position: top center;

      @include mq() {
        padding-top: 53px;
        background-size: 67px auto;
      }
    }

    &.bento {
      padding-top: 72px;
      background-image: url(../img/front/icon_bento.svg);
      background-size: 87px auto;
      background-position: top 7px center;

      @include mq() {
        padding-top: 53px;
        background-size: 58px auto;
      }
    }

    &.get {
      padding: 0 24px 104px;
      background-image: url(../img/front/icon_get.svg);
      background-size: 154px auto;
      background-position: bottom center;
      position: relative;
      letter-spacing: -0.05em;

      @include mq() {
        padding: 0 24px 69px;
        font-size: 18px;
        background-size: 118px auto;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 3px;
        height: 47px;
        background-color: $base-font-color;
        border-radius: 3px;
        position: absolute;
        top: 7px;

        @include mq() {
          height: 53px;
          top: 2px;
        }
      }

      &::before {
        left: 0;
        transform: rotate(-32deg);
      }

      &::after {
        right: 0;
        transform: rotate(32deg);
      }
    }
  }
}

.p-outline__about {
  background-color: #fff;
  border-radius: 20px;
  margin-top: 44px;
  padding: 65px 80px;
  position: relative;
  z-index: 1;

  @include mq() {
    padding: 50px 20px;
    margin-top: 24px;
  }

  .p-outline__about__text {
    @extend %fontSize_16;
    text-align: center;

    @include mq() {
      line-height: calc(28 / 14);
      text-align: left;
    }
  }

  .c-ttl {
    font-size: 34px;
    @include mq() {
      font-size: 28px;
    }
    @include mq(sp) {
      font-size: 24px;
    }
    @include mq(sssp) {
      font-size: 22px;
    }
  }
}

.p-outline__about__content {
  +.p-outline__about__content {
    // padding-top: 40px;
    // margin-top: 40px;
    // border-top: 2px solid #F2EBDB;
    // border-radius: 2px;
    margin-top: 20px;
  }
}

.p-outline__about__list {
  max-width: 662px;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;

  @include mq(sp) {
    justify-content: center;
    gap: 20px;
  }

  li {
    width: 30%;
    max-width: 198px;

    @include mq(sp) {
      width: 47%;
    }

    @include mq(ssp) {
      width: 100%;
    }
  }
}

.p-outline__about__ttl {
  @extend %fontSize_22;
  font-weight: $bold;
  line-height: calc(32 / 22);
  text-align: center;
  margin-bottom: 16px;
}

.p-outline__about__info {
  background-color: #FAF6ED;
  border-radius: 20px;
  padding: 30px;
  position: relative;
  min-height: 254px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  & + & {
    margin-top: 20px;
  }

  @include mq_up() {
    padding-right: 325px;
    position: relative;
    min-height: 254px;
    
  }
  @include mq(sp) {
    padding: 25px 20px;
  }

  .p-outline__about__info__head {
    font-size: 29px;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.4;
    word-break: keep-all;
    @media screen and (max-width: 1052px) {
      font-size: 26px;
    }
    @include mq() {
      text-align: center;
      font-size: 24px;
    }
    @include mq(sp) {
      font-size: 22px;
    }
  }
  .p-outline__about__info__text {}
  .p-outline__about__info__img {
    height: auto;
    @include mq_up() {
      width: 276px;
      position: absolute;
      right: 30px;
      top: 30px;
    }
    @include mq() {
      width: 100%;
      margin-top: 20px;
    }
  }

}
.p-outline__about__introduction {
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
  line-height: 1.7;
  font-weight: bold;
  word-break: keep-all;
  @media screen and (max-width: 1052px) {
    font-size: 20px;
  }
  @include mq(sp) {
    font-size: 18px;
  }
  @include mq(ssp) {
    font-size: 16px;
  }
  // @include mq(sssp) {
  //   font-size: 14px;
  // }
}

// ------------ スタンプラリーの楽しみ方 ------------

.p-howto {
  padding-top: 60px;
  padding-bottom: 60px;

  @include mq() {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.p-howto__stepList {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 488px));
  justify-content: center;
  gap: 24px;

  @include mq() {
    grid-template-columns: repeat(1, minmax(0, 488px));
  }
}

.p-howto__stepItem {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  padding: 30px 40px;

  @include mq() {
    padding: 20px;
  }
}

.p-howto__stepItem__txtBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    font-weight: $bold;
    line-height: 1;
    text-transform: uppercase;
    background-color: $sub-color;
    border-radius: 40px;
    padding: 6px 13px 10px;
    margin-bottom: 10px;

    @include mq() {
      font-size: 16px;
      padding: 4px 10px 7px;
    }
  }

  p {
    @extend %fontSize_25;
    font-weight: $bold;
    line-height: calc(39 / 25);
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      background: linear-gradient(transparent 80%, $acc-color 80%);
    }
  }
}

.p-howto__stepItem__imgBox {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq() {
    width: 30%;
  }

  img {
    @include mq() {
      &.--sp70per {
        width: 70%;
      }

      &.--sp80per {
        width: 80%;
      }
    }
  }
}


// ------------ プレゼント例 ------------

.p-present {
  padding-top: 93px;
  padding-bottom: 85px;

  @include mq() {
    padding-top: 19px;
    padding-bottom: 50px;
  }
}

.p-present__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 235px));
  justify-content: center;
  gap: 20px;

  @include mq() {
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: 30px;
  }

  @include mq(sp) {
    grid-template-columns: repeat(1, minmax(0, 335px));
    gap: 50px;
  }
}

.p-present__item {
  @extend %fontSize_22;
  font-weight: $bold;
  line-height: calc(28 / 22);
  text-align: center;

  p {
    font-size: 16px;
    font-weight: $bold;
    line-height: calc(28 / 16);
    text-align: center;
    padding-top: 16px;
  }
}

.p-present__item__imgBox {
  height: 214px;
  background-color: $box;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;

  @include mq() {
    margin-bottom: 10px;
  }
}


// ------------ 参加店舗を探す ------------

.p-front {
  .p-participation {
    padding-top: 106px;
    padding-bottom: 134px;
    max-width: 1366px;
    margin-inline: auto;
    position: relative;

    @include mq() {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    &::before {
      content: "";
      display: block;
      width: 153px;
      height: 334px;
      background-image: url(../img/front/sparkle_02.webp);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 15px;
      right: -24px;

      @include mq() {
        width: calc(98 / 375 * 100%);
        height: calc(202 / 375 * 100vw);
        top: 16px;
        right: -12px;
      }
    }
  }

  .p-participation__ttl {
    @extend %fontSize_30;
    font-weight: $bold;
    text-align: center;
    margin-bottom: 9px;
  }

  .p-participation__list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 238px));
    gap: 16px;
    position: relative;
    z-index: 1;

    @include mq() {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 11px;
    }

    li {
      border-radius: 20px;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 140px;
        border-radius: 20px;
        color: #fff;
        @extend %fontSize_24;
        font-weight: $bold;
        text-align: center;
        position: relative;
        z-index: 1;

        @include mq(sp) {
          height: 162px;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }

        &::before {
          background-color: $main-color;
        }

        &::after {
          background-size: cover;
          background-position: center center;
          transition: .3s;
        }

        &.hokushin {
          &::after {
            background-image: url(../img/front/img_participation_hokushin.webp);
            opacity: 0.6;
          }
        }

        &.toushin {
          &::after {
            background-image: url(../img/front/img_participation_toushin.webp);
            opacity: 0.6;
          }
        }

        &.chushin {
          &::after {
            background-image: url(../img/front/img_participation_chushin.webp);
            opacity: 0.6;
          }
        }

        &.nanshin {
          &::after {
            background-image: url(../img/front/img_participation_nanshin.webp);
            opacity: 0.6;
          }
        }

        @include hover {
          &::after {
            opacity: 1;
          }
        }

        span {
          display: block;
          @extend %fontSize_16;
          word-break: keep-all;
        }
      }
    }
  }
}


// ------------ 普段の食生活も気をつけよう ------------

.p-diet {
  max-width: 1366px;
  margin-inline: auto;
  padding-top: 113px;
  padding-bottom: 0;
  position: relative;

  @include mq() {
    padding-top: 50px;

  }

  &::before {
    content: "";
    display: block;
    width: 415px;
    height: 334px;
    background-image: url(../img/front/sparkle_03.webp);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 19px;
    left: -196px;

    @include mq() {
      width: calc(82 / 375 * 100%);
      height: calc(202 / 375 * 100vw);
      background-image: url(../img/front/sparkle_04.webp);
      top: 30%;
      left: 0;
    }

    @include mq(sp) {
      top: 59%;
    }
  }

  p {
    @extend %fontSize_16;

    @include mq() {
      line-height: calc(28 / 14);
    }
  }
}

.p-diet__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 235px));
  align-items: center;
  gap: 20px;
  margin-top: 30px;

  @include mq() {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
  }

  li {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: $bold;
    line-height: calc(20 / 16);
    text-align: center;

    .imgBox {
      height: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;

      &.--mb7 {
        margin-bottom: 7px;
      }

      img {
        object-fit: cover;
        border-radius: 20px;

        @include mq(sp) {
          width: 100%;
          object-fit: contain;
        }
      }

    }

    a {
      font-size: 14px;
      font-weight: $bold;
      line-height: calc(20 / 14);
      text-decoration: underline;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}

.p-diet__prevention {
  background-color: #fff;
  border-radius: 20px;
  padding: 50px;
  margin-top: 45px;

  @include mq() {
    padding: 50px 20px;
  }

  @include mq(sp) {
    padding: 30px 20px;
  }

  img {
    margin-inline: auto;

    @include mq() {
      max-width: 460px;
      width: 100%;
      height: auto;
    }
  }
}
.p-diet .p-diet__prevention__note {
  max-width: 713px;
  margin: 30px auto 0;
  font-size: 14px;
  color: #333;
  @include mq() {
    max-width: 460px;
    font-size: 12px;
    margin-top: 20px;
  }
  @include mq(sssp) {
    font-size: 10px;
  }
}