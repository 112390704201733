@charset "UTF-8";

// @keyframesのベンダープレフィックス対応ミックスイン
// パラメータ$nameにアニメーション名を設定
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

@include keyframes(furifuri) {
	0% {
		transform: rotate(0deg);
	}

	14% {
		transform: rotate(0deg);
	}

	18% {
		transform: rotate(-5deg);
	}

	22% {
		transform: rotate(2deg);
	}

	26% {
		transform: rotate(-5deg);
	}

	30% {
		transform: rotate(0deg);
	}
}