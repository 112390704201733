@charset "utf-8";
@use "../index" as *;

// 非表示
.u-hide {
	display: none;
}

// 表示/非表示の調整クラス
.u-hideSp {
	@include mq() {
		display: none;
	}
}

.u-hidePc {
	@include mq_up() {
		display: none;
	}
}

.u-hideLessPc {
	@include mq(pc) {
		display: none;
	}
}

.u-hideMorePc {
	@include mq_up(pc) {
		display: none;
	}
}

.u-hideLessSpc {
	@include mq(spc) {
		display: none;
	}
}

.u-hideMoreSpc {
	@include mq_up(spc) {
		display: none;
	}
}

.u-hideLessSp {
	@include mq(sp) {
		display: none;
	}
}

.u-hideMoreSp {
	@include mq_up(sp) {
		display: none;
	}
}

.u-hideLessSsp {
	@include mq(ssp) {
		display: none;
	}
}

.u-hideMoreSsp {
	@include mq_up(ssp) {
		display: none;
	}
}

.u-hideLessSssp {
	@include mq(sssp) {
		display: none;
	}
}

.u-hideMoreSssp {
	@include mq_up(sssp) {
		display: none;
	}
}