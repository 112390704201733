@charset "UTF-8";

// ---- テーマの文字スタイル ----

//FONT IMPORT
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap');

// フォント指定
$base-font-family: "Zen Maru Gothic", "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO", system-ui;
$en-font-family: "Zen Maru Gothic", system-ui;

// 文字の太さ
$black: 900;
$e-bold: 800;
$bold: 700;
$s-bold: 600;
$medium: 500;
$regular: 400;

// 行間（_reset.scssで使用）
$base-line-height: calc(28 / 16);