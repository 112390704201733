@charset 'utf-8';
@use "../index" as *;

// 404ページ
.p-notFound {
	padding: 150px 0 100px;

	h1,
	h2 {
		color: $main-color;
	}
}