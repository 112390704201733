@charset "UTF-8";

// ---- テーマの基本スタイル ----

// メインカラー
$main-color: #6B4E44;
$sub-color: #52A424;
$acc-color: #FFEC00;

// 標準フォントカラー
$base-font-color: $main-color;

// 標準ボーダーカラー
$border-color: #CCCCCC; // フォームのテーブル、hrなどのボーダーカラー

// ボックス
$box: #F2EBDB;