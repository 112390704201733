@charset "utf-8";
@use "../index" as *;

// ヘッダー

.l-header {
	width: 95%;
	max-width: 1166px;
	margin-inline: auto;
	position: relative;

	// TOPページ
	&.--front {
		width: 100%;
		max-width: 1366px;

		.l-header__logo {
			// width: 556px;
			height: auto;
			position: absolute;
			// top: 48px;
			// left: 33px;
			z-index: 1;
			width: 456px;
			top: 74px;
    	left: 58px;

			img {
				width: 100%;
				height: auto;
			}

			@include mq(pc) {
				// width: 456px;
				width: 360px;
				top: 60px;
			}

			@include mq() {
				width: 269px;
				// top: 12px;
				left: 42px;
				top: 62px;
			}
			@include mq(sp) {
				top: 52px;
			}
		}
	}

	// その他ページ
	.l-header__logo {
		width: 248px;
		height: auto;
		position: absolute;
		top: 26px;
		left: 0px;
		z-index: 1;

		@include mq() {
			width: 150px;
			top: 12px;
		}
	}
}