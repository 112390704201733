@charset "utf-8";
@use "../index" as *;

// メッセージボックス（フォームの送信完了画面と404ページで使用）
.c-basketBox {
	max-width: 700px;
	height: auto;
	margin: auto;
	text-align: center;
	padding: 100px 0;

	h1,
	h2 {
		padding: 0 0 20px;
		margin: 0 0 20px 0;
		text-align: center;
		font-weight: bold;
		@extend %fontSize_30;
		border-bottom: #DADADA 1px solid;

		// 見出しにイラストが入る場合は、背景画像としてCSSで設定すること！
		// background-image: url();
		background-position: center top;
		background-repeat: no-repeat;
		background-size: auto;
	}

	.c-basketBox__txt {
		padding-bottom: 30px;

		p {
			font-weight: bold;
			@extend %fontSize_16;

			+p {
				padding-top: 1em;
			}
		}
	}
}