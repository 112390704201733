@charset "utf-8";
@use "../mixin/breakpoint" as *;

// font size
%fontSize_40 {
	font-size: 40px;

	@include mq() {
		font-size: 32px;
	}

	@include mq(sp) {
		font-size: 28px;
	}
}

%fontSize_36 {
	font-size: 36px;

	@include mq() {
		font-size: 30px;
	}

	@include mq(sp) {
		font-size: 26px;
	}
}

%fontSize_30 {
	font-size: 30px;

	@include mq() {
		font-size: 26px;
	}

	@include mq(sp) {
		font-size: 24px;
	}
}


%fontSize_26 {
	font-size: 26px;

	@include mq() {
		font-size: 24px;
	}

	@include mq(sp) {
		font-size: 22px;
	}
}

%fontSize_25 {
	font-size: 25px;

	@include mq(spc) {
		font-size: 24px;
	}

	@include mq() {
		font-size: 22px;
	}

	@include mq(sp) {
		font-size: 18px;
	}
}

%fontSize_24 {
	font-size: 24px;

	@include mq() {
		font-size: 22px;
	}
}


%fontSize_22 {
	font-size: 22px;

	@include mq() {
		font-size: 20px;
	}
}

%fontSize_20 {
	font-size: 20px;

	@include mq() {
		font-size: 18px;
	}

	@include mq(sp) {
		font-size: 16px;
	}
}

%fontSize_19 {
	font-size: 19px;

	@include mq() {
		font-size: 18px;
	}

	@include mq(sp) {
		font-size: 16px;
	}
}

%fontSize_18 {
	font-size: 18px;

	@include mq() {
		font-size: 17px;
	}

	@include mq(sp) {
		font-size: 15px;
	}
}

%fontSize_16 {
	font-size: 16px;

	@include mq() {
		font-size: 15px;
	}

	@include mq(sp) {
		font-size: 14px;
	}
}


%fontSize_14 {
	font-size: 14px;

	@include mq() {
		font-size: 13px;
	}

	@include mq(sp) {
		font-size: 12px;
	}
}


%fontSize_12 {
	font-size: 12px;

	@include mq() {
		font-size: 12px;
	}

	@include mq(sp) {
		font-size: 11px;
	}
}


%fontSize_10 {
	font-size: 10px;

	@include mq() {
		font-size: 10px;
	}

	@include mq(sp) {
		font-size: 10px;
	}
}

// 細字
%font_normal {
	font-weight: normal;
}

// 太字
%font_bold {
	font-weight: bold;
}