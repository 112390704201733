@charset "utf-8";
@use "../index" as *;

// 電話番号など、PCではリンクさせず、SPだけクリッカブルにする
.u-linkSp {
  pointer-events: none;

  @include mq() {
    pointer-events: inherit;
  }
}