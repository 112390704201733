@charset 'utf-8';
@use "../index" as *;

.l-cta {
  background-image: url(../img/common/bg_cta.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 37px 20px 74px;

  @include mq() {
    padding: 50px 20px;
  }
}

.l-cta__ttlWrap {
  text-align: center;
}

.l-cta__ttl {
  display: inline-block;
  color: #fff;
  @extend %fontSize_36;
  font-weight: $bold;
  line-height: calc(52 / 36);
  text-align: center;
  position: relative;
  padding: 0 55px;
  margin-bottom: 20px;

  @include mq() {
    line-height: calc(37 / 26);
    padding: 0 35px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 32px;
    height: 31px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 20px;

    @include mq() {
      width: 42px;
      height: 41px;
      bottom: -10px;
    }
  }

  &::before {
    background-image: url(../img/common/line_left.svg);
    left: 0;

    @include mq() {
      left: -11px;
    }
  }

  &::after {
    background-image: url(../img/common/line_right.svg);
    right: 0;

    @include mq() {
      right: -11px;
    }
  }
}

.l-cta__content {
  max-width: 749px;
  margin-inline: auto;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 78px;

  @include mq() {
    flex-direction: column;
    padding: 30px 28px;
  }
}

.l-cta__btn {
  height: 56px;
  padding: 0 12px 0 47px;
  font-size: 16px;
  background-position: left 17px center;

  &::after {
    margin-left: 31px;
  }
}

.l-cta__qr {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq() {
    margin-top: 27px;
  }

  img {
    margin-right: 18px;
  }

  p {
    @extend %fontSize_16;
    font-weight: $bold;
    line-height: calc(24 / 16);
  }
}