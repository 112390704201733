@charset "utf-8";
@use "../index" as *;

// text align
.u-textLeft {
	text-align: left;
}

.u-textRight {
	text-align: right;
}

.u-textCenter {
	text-align: center;
}